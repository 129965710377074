.container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon-wrapper {
    display: inline-flex;
    padding: 10px;
    border-radius: 5px;
    font-size: 30px;
}

.text-wrapper {
    display: inline-flex;
    flex-direction: column;

    h3, h4 {
        margin: 0;
    }
}